import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  return _openBlock(), _createBlock(_component_van_tabbar, {
    modelValue: $data.activeName,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.activeName = $event),
    onChange: _cache[1] || (_cache[1] = $event => $options.jumpTo())
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
      name: "home",
      icon: "wap-home-o"
    }, {
      default: _withCtx(() => [_createTextVNode("首页")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      name: "news",
      icon: "todo-list-o"
    }, {
      default: _withCtx(() => [_createTextVNode("消息")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      name: "schedule",
      icon: "calendar-o"
    }, {
      default: _withCtx(() => [_createTextVNode("日程")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      name: "photo",
      icon: "photo-o"
    }, {
      default: _withCtx(() => [_createTextVNode("相册")]),
      _: 1
    }), _createVNode(_component_van_tabbar_item, {
      name: "user",
      icon: "user-o"
    }, {
      default: _withCtx(() => [_createTextVNode("我的")]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]);
}