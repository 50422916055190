import "core-js/modules/es.array.push.js";
export default {
  props: {
    active: String
  },
  data() {
    return {
      activeName: this.active
    };
  },
  created() {},
  methods: {
    jumpTo() {
      this.$router.push(this.activeName);
    }
  }
};